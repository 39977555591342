import {    
  signInWithPopup, 
  GoogleAuthProvider, 
  signOut, 
  sendEmailVerification 
} from 'firebase/auth'
import { doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore'
import { auth, db } from '../../firebase'

const state = {
  user: null,
  userData: null,
}

const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_USER_DATA(state, userData) {
    state.userData = userData
  },
}

const actions = {
  async signInWithGoogle({ commit, dispatch }) {
    const provider = new GoogleAuthProvider()
    try {
      const result = await signInWithPopup(auth, provider)
      commit('SET_USER', result.user)
      await dispatch('fetchUserData', result.user.uid)
      return `Welcome, ${result.user.displayName || result.user.email}!`
    } catch (error) {
      console.error('Error signing in with Google:', error)
      throw new Error('Error signing in with Google. Please try again.')
    }
  },

  async signOut({ commit }) {    
    try {
      await signOut(auth)
      commit('SET_USER', null)
      commit('SET_USER_DATA', null)
      return 'You have been signed out successfully.'
    } catch (error) {
      console.error('Error signing out:', error)
      throw new Error('Error signing out. Please try again.')
    }
  },

  async resendVerificationEmail() {
    try {
      await sendEmailVerification(auth.currentUser)
      return 'Verification email sent. Please check your inbox.'
    } catch (error) {
      console.error('Error sending verification email:', error)
      throw new Error('Error sending verification email. Please try again.')
    }
  },

  async updateUser({ commit, dispatch }, user) {
    commit('SET_USER', user)
    if (user) {
      await dispatch('fetchUserData', user.uid)
    } else {
      commit('SET_USER_DATA', null)
    }
  },

  async fetchUserData({ commit }, uid) {
    try {
      const userDocRef = doc(db, 'users', uid)
      const userDocSnap = await getDoc(userDocRef)
      
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data()
        // Update last login
        await updateDoc(userDocRef, { lastLogin: serverTimestamp() })
        
        // Convert Firestore Timestamps to JavaScript Date objects
        const processedUserData = Object.entries(userData).reduce((acc, [key, value]) => {
          if (value && typeof value.toDate === 'function') {
            acc[key] = value.toDate()
          } else {
            acc[key] = value
          }
          return acc
        }, {})

        processedUserData.lastLogin = new Date() // Update local state with current time
        commit('SET_USER_DATA', processedUserData)
      } else {
        // If the user document doesn't exist, create it
        const newUserData = {
          createdAt: serverTimestamp(),
          lastLogin: serverTimestamp(),
          // Add any other initial user data here
        }
        await setDoc(userDocRef, newUserData)
        
        const processedNewUserData = {
          ...newUserData,
          createdAt: new Date(),
          lastLogin: new Date()
        }
        commit('SET_USER_DATA', processedNewUserData)
      }
    } catch (error) {
      console.error('Error fetching user data:', error)
      throw new Error('Error fetching user data. Please try again.')
    }
  },

  async updateUserData({ state, commit }, newData) {
    if (!state.user) {
      throw new Error('No user is currently signed in.')
    }

    try {
      const userDocRef = doc(db, 'users', state.user.uid)
      const updateData = { ...newData, lastLogin: serverTimestamp() }
      await updateDoc(userDocRef, updateData)

      // Fetch the updated document to get the server-generated timestamps
      const updatedDocSnap = await getDoc(userDocRef)
      const updatedData = updatedDocSnap.data()

      // Convert Firestore Timestamps to JavaScript Date objects
      const processedUpdatedData = Object.entries(updatedData).reduce((acc, [key, value]) => {
        if (value && typeof value.toDate === 'function') {
          acc[key] = value.toDate()
        } else {
          acc[key] = value
        }
        return acc
      }, {})

      commit('SET_USER_DATA', processedUpdatedData)
    } catch (error) {
      console.error('Error updating user data:', error)
      throw new Error('Error updating user data. Please try again.')
    }
  },
}

const getters = {
  isAuthenticated: (state) => !!state.user,
  currentUser: (state) => state.user,
  userData: (state) => state.userData,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}